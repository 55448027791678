import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const TermsAndConditions = () => {
    return (
        <>
            <Navbar />
            <div className='mx-[20px] overflow-hidden'>

                <div className=" mx-auto px-4 py-8">
                    <h2 className="pl-0 mb-10 text-21xl leading-none text-center "><span className="bg-black text-white px-4 rounded-lg">Terms and Conditions</span></h2>

                    <div className="text-lg mb-6">
                        <h2 className="text-xl font-bold  mb-2 text-justify">1. User Accounts</h2>
                        <p className="mb-2 text-justify">1.1 To access certain features of the Services, you may be required to create an account ("Account"). You are responsible for maintaining the confidentiality of your account information, including your username and password, and for all activity that occurs under your account. You agree to notify cuet.testknock.com immediately of any unauthorized use of your account or any other security breach.</p>
                    </div>

                    <div className="text-lg mb-6">
                        <h2 className="text-xl font-bold  mb-2 text-justify">2. User Content</h2>
                        <p className="mb-2 text-justify">2.1 You may be permitted to submit content to the Website, including testimonials, reviews, and comments ("User Content"). You retain ownership of any intellectual property rights you hold in your User Content. However, by submitting User Content to the Website, you grant cuet.testknock.com a non-exclusive, irrevocable, royalty-free, worldwide license to use, reproduce, modify, publish, distribute, and translate your User Content in connection with the Services.</p>
                        <p className="mb-2 text-justify">2.2 You are solely responsible for your User Content and represent and warrant that your User Content does not infringe on the intellectual property rights or other rights of any third party. You agree not to submit User Content that is foul, offensive, or unlawful.</p>
                        <p className="mb-2 text-justify">2.3 cuet.testknock.com reserves the right, at its sole discretion, to remove or edit any User Content that it deems to be in violation of these Terms.</p>
                    </div>

                    <div className="text-lg mb-6">
                        <h2 className="text-xl font-bold  mb-2 text-justify">3. Third-Party Content</h2>
                        <p className="mb-2 text-justify">3.1 The Website may contain content provided by third parties ("Third-Party Content"). cuet.testknock.com does not control or endorse any Third-Party Content. You acknowledge and agree that cuet.testknock.com is not responsible for the accuracy, concurrency, suitability, reliability, or quality of the Third-Party Content.</p>
                        <p className="mb-2 text-justify">3.2 You agree to use the Third-Party Content at your own risk. cuet.testknock.com disclaims all liability for any harm arising from your use of the Third-Party Content.</p>
                    </div>

                    <div className="text-lg mb-6">
                        <h2 className="text-xl font-bold  mb-2 text-justify">4. Intellectual Property Rights</h2>
                        <p className="mb-2 text-justify">4.1 cuet.testknock.com Ownership: The Website and all its content and materials, including the site layout, software, images, text, services, and similar materials, are the intellectual property of cuet.testknock.com or its licensors.</p>
                        <p className="mb-2 text-justify">4.2 Restrictions on Use: You agree not to use any trademarks, logos, or service marks of cuet.testknock.com without prior written permission. You may only download or print content from the Website for your personal, non-commercial use. Modifying, copying, reproducing, distributing, transmitting, displaying, publishing, selling, licensing, creating derivative works from, or otherwise exploiting any content from the Website is strictly prohibited without prior written permission from cuet.testknock.com. This also applies to data mining, scraping, and republishing of content from the Website.</p>
                        <p className="mb-2 text-justify">4.3 Data mining, scraping, and republishing of content from the Website is strictly prohibited.</p>
                    </div>

                    <div className="text-lg mb-6">
                        <h2 className="text-xl font-bold  mb-2 text-justify">5. External Links</h2>
                        <p className="mb-2 text-justify">5.1 Links to Third-Party Websites: The Website may contain links to third-party websites ("External Links"). cuet.testknock.com is not responsible for the content or practices of any External Links.</p>
                        <p className="mb-2 text-justify">5.2 User Acknowledgment: You acknowledge and agree that cuet.testknock.com does not endorse, warrant, or guarantee the products, services, or information offered by any External Links.</p>
                        <p className="mb-2 text-justify">5.3 Use at Your Own Risk: You agree to use External Links at your own risk, and cuet.testknock.com disclaims all liability for any harm arising from your use of External Links.</p>
                    </div>

                    <div className="text-lg mb-6">
                        <h2 className="text-xl font-bold  mb-2 text-justify">6. Disclaimer of Warranties</h2>
                        <p className="mb-2 text-justify">6.1 Services Provided As-Is: The Services are provided "as is" and "as available" without any warranties, expressed or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
                        <p className="mb-2 text-justify">6.2 No Guarantees: cuet.testknock.com does not warrant that the Services will be uninterrupted, secure, or error-free. Additionally, they do not warrant that the results you obtain from using the Services will be accurate or reliable.</p>
                    </div>

                    <div className="text-lg mb-6">
                        <h2 className="text-xl font-bold  mb-2 text-justify">7. Limitation of Liability</h2>
                        <p className="mb-2 text-justify">7.1 TO THE MAXIMUM EXTENT PERMITTED BY LAW, CUET.TESTKNOCK.COM SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICES. This includes but is not limited to damages for loss of profits, data, goodwill, or use, even if cuet.testknock.com has been advised of the possibility of such damages.</p>
                    </div>

                    <div className="text-lg mb-6">
                        <h2 className="text-xl font-bold  mb-2 text-justify">8. Termination</h2>
                        <p>8.1 - By Cuet.testknock.com: cuet.testknock.com may terminate your access to the Services at any time, with or without notice, for any reason.</p>
                        <p>8.2 - By You: You may terminate your account at any time by following the instructions on the Website.</p>
                    </div>

                    <div className="text-lg mb-6">
                        <h2 className="text-xl font-bold  mb-2 text-justify">9. Governing Law and Dispute Resolution</h2>
                        <p>9.1 These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions.</p>
                        <p>9.2 Any dispute arising out of or relating to these Terms or your use of the Services shall be subject to the exclusive jurisdiction of the courts located in Gurugram, India.</p>
                    </div>

                    <div className="text-lg mb-6">
                        <h2 className="text-xl font-bold  mb-2 text-justify">10. Entire Agreement</h2>
                        <p>10.1 These Terms constitute the entire agreement between you and cuet.testknock.com regarding your use of the Services.</p>
                    </div>

                    <div className="text-lg mb-6">
                        <h2 className="text-xl font-bold  mb-2 text-justify">11. Changes to these Terms</h2>
                        <p>11.1 cuet.testknock.com reserves the right to update these Terms at any time. We will notify you of any changes by posting the new Terms on the Website. You are advised to review these Terms periodically for any changes. Your continued use of the Services after the posting of any revised Terms constitutes your acceptance of the revised Terms.</p>
                    </div>

                    <div className="text-lg mb-6">
                        <h2 className="text-xl font-bold  mb-2 text-justify">12. Contact Us</h2>
                        <p>If you have any questions about this Privacy Policy, please contact us:</p>
                        <ul className="list-disc ml-4">
                            <li className="mb-2 ">Email:<a href="mailto:info@testknock.com"> info@testknock.com</a></li>
                            <li className="mb-2">Phone:<a href="tel:9560443520"> 9560443520</a></li>
                            <li>Or by visiting this page on our website: - <a href="https://cuet.testknock.com/contact">https://cuet.testknock.com/contact</a></li>
                        </ul>
                    </div>
                </div>
                );

            </div>
            <Footer />
        </>
    )
}

export default TermsAndConditions;
