import React from "react";
import { IoMdCall, IoMdMail, IoMdPin } from "react-icons/io";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      className="bg-black text-white w-full flex  justify-center items-center relative z-50"
    // style={{
    //   backgroundImage:
    //     "linear-gradient(to right, #452760, #3d315c, #111f58, #231f7a)",
    // }}
    >
      <div className="w-full  max-md:mx-5 overflow-hidden">
        <div className="flex my-6 justify-center items-center lg:mr-[3rem]">
          <div className="grid md:grid-cols-2 md:mx-10 grid-cols-1 w-fit">
            <div className=" md:flex md:flex-col justify-center ml-[34px] max-md:ml-[0px] w-fit">
              <div className="flex justify-start items-center w-fit">
                <Link
                  to="/"
                  className="no-underline d-flex justify-content-center mt-4 align-items-center flex-column"
                >
                  <div className="flex items-center">
                    <div className="mr-4 mb-[-12px]">
                      <img
                        src={"/logo.png"}
                        alt="Logo"
                        className="max-h-[60px] max-w-[60px] pr-4 pb-2"
                      />
                    </div>
                    <div className="text-white font-sans font-bold text-[100%] ml-[-8%]">
                      <h2>
                        <span>CUET-TESTKNOCK</span>
                      </h2>
                    </div>
                  </div>
                </Link>
              </div>
              <p className="md:w-[35vw] font-normal text-justify">
                At TestKnock, we are dedicated to more than just academic
                success. Our unwavering commitment extends to providing you with
                the essential tools and expert guidance. So join us on this
                transformative expedition and discover where educational
                excellence is not just an aspiration but an inspiring and
                achievable pursuit.
              </p>
            </div>
            <div className="w-fit relative md:top-[35px] grid grid-cols-2 md:grid-cols-3 text-sm md:text-md md:ml-[-64px] sm:m-0">
              <div className="min-w-[10em] mx-auto my-5 md:my-0 md:ml-0">
                <p className="cursor-pointer font-bold mb-10  md:text-center">
                  Company
                </p>
                <Link to="/" className="no-underline text-white">
                  <p className="font-normal my-3 md:text-center hover:text-red-500 hover:cursor-pointer">
                    Home
                  </p>
                </Link>
                <Link to="/about" className="no-underline text-white">
                  <p className="font-normal my-3 md:text-center hover:text-red-500 hover:cursor-pointer">
                    About
                  </p>
                </Link>
                <Link to="/syllabus" className="no-underline text-white">
                  <p className="font-normal my-3 md:text-center hover:text-red-500 hover:cursor-pointer">
                    Syllabus
                  </p>
                </Link>
                <Link to="/courses" className="no-underline text-white">
                  <p className="font-normal my-3 md:text-center hover:text-red-500 hover:cursor-pointer">
                    Courses
                  </p>
                </Link>
                <Link to="/purchase" className="no-underline text-white">
                  <p className="font-normal my-3 md:text-center hover:text-red-500 hover:cursor-pointer">
                    Pricing
                  </p>
                </Link>
              </div>
              <div className="min-w-[10em] mx-auto my-5 md:my-0 md:ml-[-15px]">
                <p className="md:text-center font-bold mb-10">Whom we serve</p>
                <p className="md:text-center font-normal my-3 hover:text-red-500">
                  School Students
                </p>
                <p className="md:text-center font-normal my-3 hover:text-red-500">
                  College Students
                </p>
                <p className="md:text-center font-normal my-3 hover:text-red-500">
                  Educational Institutions
                </p>
                <p className="md:text-center font-normal my-3 hover:text-red-500">
                  Private Colleges
                </p>
              </div>
              <div className="min-w-[10em] mx-auto my-5 md:my-0 md:ml-[29px]">
                <p className="ml-4 font-bold mb-10">Contact US</p>
                <a
                  href="mailto:info@testknock.com"
                  className="hover:opacity-80 no-underline"
                >
                  <p className="md:text-center font-normal flex items-center my-3 text-white hover:text-red-500 hover:cursor-pointer">
                    <span className="flex justify-center items-center mr-2">
                      <IoMdMail className="text-[1.1em] md:text-[1.5em]" />
                    </span>
                    info@testknock.com
                  </p>
                </a>
                <a
                  href="tel:9560443520"
                  className="hover:opacity-80 no-underline"
                >
                  <p className="md:text-center font-normal flex items-center my-3 text-white hover:text-red-500 hover:cursor-pointer">
                    <span className="flex justify-center items-center mr-2">
                      <IoMdCall className="text-[1.1em] md:text-[1.5em]" />
                    </span>
                    9560443520
                  </p>
                </a>
                <a
                  href="https://maps.google.com/?q=Office+No.+42,+Durga+Vihar,+near+Amrapali+Sapphire,+Sec-45,+Noida-201301"
                  className="hover:opacity-80 no-underline"
                >
                  <p className="flex justify-between my-3 text-white hover:text-red-500 hover:cursor-pointer">
                    <span>
                      <IoMdPin className="text-[1.1em] md:text-[1.5em]" />
                    </span>
                    <span className="ml-2">
                      Office No. - 42, Durga Vihar, near Amrapali Saphhire,
                      Sec-45, Noida-201301
                    </span>
                  </p>
                </a>
              </div>
              <div className="min-w-[10em] mx-auto my-5 md:hidden">
                <p className="font-bold mb-10  md:text-center">Support</p>
                <p>  <Link to="/terms-and-conditions" className="my-3 text-white no-underline md:text-center">Terms & Conditions</Link></p>
                <p> <Link to="/cancellation-and-refund" className="my-3 text-white no-underline md:text-center">Cancellation/Refund Policy</Link></p>
                <p> <Link to="/privacy-policy" className="my-3 text-white no-underline md:text-center">Privacy Policy</Link></p>
                <p> <Link to="/shipping-policy" className="my-3 text-white no-underline md:text-center">Shipping Policy</Link></p>
              </div>
            </div>
          </div>
        </div>


        <div style={{borderTop:"2px solid white" , borderBottom:"2px solid white"}} className="hidden md:flex opacity-85 mx-auto flex-col my-3 py-3 items-center justify-center  px-px box-border gap-[15px] max-w-full ">
          <div className="w-[90%] mx-auto flex flex-row items-start justify-start py-0 box-border max-w-full">
            <div className="flex-1 flex flex-row items-start justify-between max-w-full ">
              <div className="flex text-[15px] flex-row items-center w-full justify-between gap-[20px] text-white">
                <Link to="/terms-and-conditions" className="relative z-[1]  text-white font-semibold no-underline hover:text-red-500 hover:cursor-pointer">
                  Terms & Conditions
                </Link>
                <Link to="/cancellation-and-refund" className="relative z-[1] text-white font-semibold no-underline hover:text-red-500 hover:cursor-pointer">
                  Cancellation/Refund Policy
                </Link>
                <Link to="/privacy-policy" className="relative z-[1] text-white font-semibold no-underline hover:text-red-500 hover:cursor-pointer">
                  Privacy Policy
                </Link>
                <Link to="/shipping-policy" className="relative z-[1] text-white font-semibold no-underline hover:text-red-500 hover:cursor-pointer">
                  Shipping Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
        <hr className="w-[100%] md:hidden mx-auto  text-white" />
        <div className="flex my-3 text-[15px]  flex-row opacity-90 items-center justify-center max-w-full mq750:flex-wrap mq450:gap-[76px]">
            <p8 className=" font-semibold relative z-[1]">
            &copy; Testknock Technologies Private Limited.
            </p8>
        </div>


      </div>

    </footer>
  );
};

export default Footer;
