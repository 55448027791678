import React from "react";
import Vector from "../../src/assets/images/Vector_syllabus_01.png";
import Vector2 from "../../src/assets/images/Vector_syllabus_02.png";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SyllabusCard from "../components/Syllabus/SyllabusCard";
import { subjects } from "../utils/constants";
const Syllabus = () => {

  return (
    <>
      {/* <Navbar /> */}
      <div className="w-full overflow-hidden relative pb-0 box-border tracking-[normal]">
        <img src={Vector} className="absolute top-0 left-0 z-0 overflow-hidden" alt="" />
        <Navbar />
        <div className="relative max-w-[1280px] *:  mx-auto  z-10">
        <div className=" mt-10 mb-14 ml-5">
            <h2 className="pl-0 mb-1 max-md:hidden text-21xl leading-none sm:justify-center md:text-left lg:text-left"><span className="bg-black text-white px-4 rounded-lg">Syllabus</span></h2>
            <h3 className="md:text-center md:hidden text-5xl rounded-lg  max-md:mx-3 max-md:px-2   text-center font-bold   md:text-13xl mt-2 text-white"><span className="bg-black px-6  rounded-lg py-1">Syllabus</span></h3>

            <div className="w-10 m-0 h-[2px] max-md:mx-auto mt-4 mb-2 bg-black " />
          </div>
          <div className="flex justify-center items-center">
             <div className="grid items-center  grid-cols-1 mb-8 lg:grid-cols-5 md:grid-cols-3  justify-center   lg:m-[6px]">
            {subjects.map((subject) => (
              <SyllabusCard subject={subject} />
            ))}
          </div>
          </div>
         
        </div>
        <img src={Vector2} className="absolute bottom-0 right-0 z-0 overflow-hidden" alt="" />
        <Footer />
      </div>
    </>
  );
};

export default Syllabus;