import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const PrivacyPolicy = () => {
    return (
        <div>
            <Navbar />

            <div className="text-justify overflow-hidden md:mx-[20px] mx-10 px-4 py-8">
                <h2 className="pl-0 mb-10 text-21xl leading-none text-center "><span className="bg-black text-white px-4 rounded-lg">Privacy Policy</span></h2>
                <p className="text-lg text-gray-900 mb-8">We at cuet.testknock.com ("TestKnock", "we", "us", or "our") are committed to respecting your online privacy and recognize your need for appropriate protection and management of any "Personal Information" you share with us. This Privacy Policy describes how we collect, use, disclose, and otherwise process your personal information when you use our website, mobile site, mobile app, and other online services (collectively, the "Services").</p>

                <h2 className="text-2xl font-bold mb-4">Types of Personal Information We Collect</h2>
                <p className="text-lg text-gray-900 mb-4">We collect the following types of personal information when you use the Services:</p>
                <ul className="list-disc text-lg md:ml-6 ml-2 mb-8">
                    <li className="mb-2 font-bold">Information you provide directly to us:</li>
                    <ul className="list-disc text-lg md:ml-6 ml-2 mb-4">
                        <li>Create an account on cuet.testknock.com, such as your name, email address, and password.</li>
                        <li>Sign up for a newsletter or mailing list.</li>
                        <li>Make a purchase, such as a subscription or practice test package.</li>
                        <li>Participate in a survey, contest, or promotion.</li>
                        <li>Contact us for support, such as your name, email address, and a description of your inquiry.</li>
                    </ul>
                    <li className="mb-2 font-bold">Information collected automatically:</li>
                    <ul className="list-disc text-lg md:ml-6 ml-2 mb-4">
                        <li>Your IP address</li>
                        <li>Browser type and operating system</li>
                        <li>Device type (computer, phone, tablet, etc.)</li>
                        <li>The pages you visit on our website and mobile app</li>
                        <li>The time and date of your visit</li>
                        <li>Your search queries on cuet.testknock.com</li>
                        <li>Your interactions with our features and functionality</li>
                    </ul>
                    <li className="mb-2 font-bold">Information from third parties:</li>
                    <ul className="list-disc text-lg md:ml-6 ml-2">
                        <li>Social media platforms, if you choose to connect your social media account to your cuet.testknock.com account.</li>
                        <li>Marketing partners, who may provide us with information about your interests and online activity to help us better tailor our advertising to you.</li>
                    </ul>
                </ul>

                <h2 className="text-2xl font-bold mb-4">Use of Your Personal Information</h2>
                <p className="text-lg text-gray-900 mb-4">We use your personal information for the following purposes:</p>
                <ul className="list-disc text-lg md:ml-6 ml-2 mb-8">
                    <li className="mb-2 font-bold">To provide and operate the Services:</li>
                    <ul className="list-disc text-lg md:ml-6 ml-2 mb-4">
                        <li>Allowing you to create and manage your cuet.testknock.com account</li>
                        <li>Providing you with practice tests, study materials, and other educational resources</li>
                        <li>Processing your payments and fulfilling your orders</li>
                        <li>Delivering personalized learning recommendations and experiences</li>
                        <li>Administering your account and responding to your inquiries</li>
                    </ul>
                    <li className="mb-2 font-bold">To send you marketing and promotional communications:</li>
                    <ul className="list-disc text-lg md:ml-6 ml-2 mb-4">
                        <li>We may use your personal information to send you marketing emails about our products and services, such as new practice tests, upcoming courses, and special offers. You can opt-out of receiving these communications at any time.</li>
                    </ul>
                    <li className="mb-2 font-bold">To personalize your experience:</li>

                    <ul className="list-disc text-lg md:ml-6 ml-2 mb-4">
                        <li>
                            We use your personal information to personalize your experience with the Services, such as:
                        </li>
                        <li>
                            Recommending practice tests and study materials that are relevant to your interests and goals            </li>
                        <li>
                            Tailoring the content and functionality of the Services to your learning style            </li>
                        <li>
                            Tracking your progress and performance to help you stay motivated            </li>

                    </ul>

                    <li className="mb-2 font-bold">To analyze service usage and improve our offerings:</li>

                    <ul className="list-disc text-lg md:ml-6 ml-2 mb-4">
                        <li>
                            We use your personal information to analyze how users interact with the Services. This helps us improve the Services, develop new features, and provide a better overall user experience.            </li>

                    </ul>
                    <li className="mb-2 font-bold">To comply with legal and regulatory requirements: </li>
                    <ul className="list-disc text-lg md:ml-6 ml-2 mb-4">
                        <li>
                            We may use your personal information to comply with applicable laws and regulations, such as responding to court orders or subpoenas.
                        </li>
                    </ul>
                    <li className="mb-2 font-bold">To prevent fraud and abuse: </li>
                    <ul className="list-disc text-lg md:ml-6 ml-2 mb-4">
                        <li>
                            We may use your personal information to prevent and detect fraud and abuse of the Services.          </li>
                    </ul>
                </ul>

                <h2 className="text-2xl font-bold mb-4">Sharing of Your Personal Information</h2>
                <p className="text-lg text-gray-900 mb-4">We will not share your personal information with third parties except in the following limited circumstances:</p>
                <ul className="list-disc text-lg md:ml-6 ml-2 mb-8">
                    <li className="mb-2 font-bold">Service providers:</li>
                    <ul className="list-disc text-lg md:ml-6 ml-2 mb-4">
                        <li>We may share your personal information with third-party service providers who help us operate the Services. These service providers are contractually obligated to keep your information confidential and secure and to use it only for the purposes we have specified.</li>
                    </ul>
                    <li className="mb-2 font-bold">Business partners:</li>
                    <ul className="list-disc text-lg md:ml-6 ml-2 mb-4">
                        <li>We may share your personal information with business partners, such as educational institutions or test preparation companies, to offer you complementary products and services that may be of interest to you. We will only share your information with business partners with your consent.</li>
                    </ul>
                    <li className="mb-2 font-bold">Legal and regulatory requirements:</li>
                    <ul className="list-disc text-lg md:ml-6 ml-2 mb-4">
                        <li>We may disclose your personal information if required by law, such as to comply with a court order or subpoena, or in response to a government request.</li>
                    </ul>
                    <li className="mb-2 font-bold">Merger or acquisition:</li>
                    <ul className="list-disc text-lg md:ml-6 ml-2 mb-4">
                        <li>In the event of a merger, acquisition, or asset sale involving cuet.testknock.com, your personal information may be transferred to the new owner.</li>
                    </ul>
                </ul>

                <h2 className="text-2xl font-bold mb-4">What You Can Do!</h2>
                <p className="text-lg text-gray-900 mb-4">You have choices about your personal information:</p>
                <ul className="list-disc text-lg md:ml-6 ml-2 mb-8">
                    <li className="mb-2 font-bold">Access and correction:</li>
                    <ul className="list-disc text-lg md:ml-6 ml-2 mb-4">
                        <li>You can access and update your personal information through your account settings on cuet.testknock.com. You can also request to delete your account by contacting us.</li>
                    </ul>
                    <li className="mb-2 font-bold">Marketing communications:</li>
                    <ul className="list-disc text-lg md:ml-6 ml-2 mb-4">
                        <li>You can opt-out of receiving marketing emails from cuet.testknock.com by clicking the "unsubscribe" link at the bottom of any marketing email you receive from us. You can also manage your communication preferences through your account settings.</li>
                    </ul>
                    <li className="mb-2 font-bold">Cookies:</li>
                    <ul className="list-disc text-lg md:ml-6 ml-2 mb-4">
                        <li>We use cookies and similar tracking technologies to track the activity on our Services and hold certain information. Cookies are small files that are placed on your device when you visit a website. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of the Services.</li>
                    </ul>
                </ul>

                <h2 className="text-2xl font-bold mb-4">Data Security</h2>
                <p className="text-lg text-gray-900 mb-4">We take reasonable steps to protect your personal information from unauthorized access, disclosure, alteration, or destruction. These steps include:</p>
                <ul className="list-disc text-lg md:ml-6 ml-2 mb-8">
                    <li className="mb-2 ">Using secure servers to store your personal information</li>
                    <li className="mb-2 ">Implementing access controls to limit who can access your information</li>
                    <li className="mb-2 ">Regularly monitoring our systems for potential security vulnerabilities</li>
                </ul>
                <p className="text-lg text-gray-900 mb-4">However, no internet or electronic storage system is 100% secure. We cannot guarantee the security of your personal information.</p>


                <h2 className="text-2xl font-bold mb-4">Student Privacy</h2>
                <p className="text-lg text-gray-900 mb-4">cuet.testknock.com is intended for users 13 and older. We understand the importance of protecting the privacy of minors and we do not knowingly collect personal information from anyone under the age of 13.</p>
                <p className="text-lg text-gray-900 mb-4">If you are a parent or guardian and believe your child under 13 has provided us with personal information, please contact us immediately. We will take steps to verify the information and delete it from our systems.</p>

                <h2 className="text-2xl font-bold mb-4">Changes to this Privacy Policy</h2>
                <p className="text-lg text-gray-900 mb-4">We may update this Privacy Policy from time to time. We will post any changes to this Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.</p>

                <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
                <p className="text-lg text-gray-900 mb-4">If you have any questions about this Privacy Policy, please contact us:</p>
                <ul className="list-disc text-lg md:ml-6 ml-2 mb-8">
                    <li className="mb-2 ">Email:<a href="mailto:info@testknock.com"> info@testknock.com</a></li>
                    <li className="mb-2">Phone:<a href="tel:9560443520"> 9560443520</a></li>
                    <li className="mb-2 ">Or by visiting this page on our website: - <a href="https://cuet.testknock.com/contact">https://cuet.testknock.com/contact</a></li>
                </ul>
            </div>
            <Footer />

        </div>
    );
};

export default PrivacyPolicy;