import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const CancellationRefundPolicy = () => {
    return (
        <>
            <Navbar />
            <div className="mx-[20px] overflow-hidden px-4 py-8">
                <h2 className="pl-0 mb-10 text-21xl leading-none text-center "><span className="bg-black text-white px-4 rounded-lg">Cancellation and Refund Policy</span></h2>

                <div className="text-lg mb-6">
                    <p className="mb-4  text-justify">
                        At cuet.testknock.com, we strive to provide valuable resources to help you prepare for your CUET exam. Due to the digital nature of our products, all purchases made on our website are final and non-refundable. This policy applies to everything you buy on cuet.testknock.com, including:
                    </p>
                    <ul className="list-disc ml-8 mb-4 text-justify">
                        <li>Practice tests in the package</li>
                        <li>Mock test in the package</li>
                        <li>Personalized dashboard</li>
                        <li>Syllabus for the subjects in the package</li>
                    </ul>
                </div>

                <div className="text-lg mb-6">
                    <h2 className="text-xl font-bold mb-2 text-justify">Why We Can't Offer Refunds</h2>
                    <p className="mb-2 text-justify">
                        There are two main reasons we cannot offer refunds on cuet.testknock.com:
                    </p>
                    <ul className="list-disc ml-8 mb-4 text-justify">
                        <li className='mb-7'><b>Instant access to digital products:</b> At cuet.testknock.com, we believe in providing immediate access to the resources you need to excel in your CUET exam. The moment your purchase is confirmed, you'll be granted access to the entire digital product package you've chosen. This includes practice tests you can take right away to gauge your understanding, downloadable study materials you can reference at your convenience, and any additional resources that come with your specific package. This instant access ensures you can begin your exam preparation journey without delay. However, it's important to remember that with this convenience comes the responsibility of making an informed decision before purchase.</li>
                        <li><b>Non-returnable nature of digital content:</b> Due to the digital nature of our products, once you download or access any study materials or practice tests, we are unable to retract them from your device. This differs from physical products where returns and exchanges can be facilitated. The digital format allows for immediate use and limitless duplication on your personal devices. While this offers flexibility in how you approach your studies, it also means that after you access the content, we cannot process a refund because we cannot control what happens with the digital files once they are in your possession. We recommend thoroughly reviewing product descriptions before purchasing to ensure you understand exactly what's included in each package.</li>
                    </ul>
                </div>

                <div className="text-lg mb-6">
                    <h2 className="text-xl font-bold mb-2 text-justify">Understanding Your Options</h2>
                    <p className="mb-2 text-justify">
                        We understand that unforeseen circumstances may arise that might lead you to request a refund. While we cannot offer refunds due to the reasons mentioned above, we want to suggest some steps you can take:
                    </p>
                    <ul className="list-disc ml-8 mb-4 text-justify">
                        <li className='mb-3'><b>Contact Us Before Your Purchase: </b>  If you have any doubts or questions about a product or our policy, please don't hesitate to reach out to us before making a purchase. </li>
                        <ul className="list-disc ml-8 mb-4 text-justify">
                            <li className="mb-2 ">Our email address is<a href="mailto:info@testknock.com"> info@testknock.com</a></li>
                            <li className="mb-2">or call us at<a href="tel:9560443520"> 9560443520</a></li>
                        </ul>
                        <p>and we're happy to help you choose the most suitable product for your needs.</p>
                        <li><b>Review Product Descriptions Carefully: </b>  We encourage you to thoroughly read the descriptions of each product before purchasing. The descriptions detail what's included in each package, ensuring you understand what you're buying.</li>
                    </ul>
                </div>

                <div className="text-lg mb-6">
                    <h2 className="text-xl font-bold mb-2 text-justify">Commitment of cuet.testknock.com</h2>
                    <p className="mb-2 text-justify">
                        We appreciate your understanding of our policy. cuet.testknock.com remains committed to providing high-quality resources to help you succeed in your CUET exam. If you have any further questions, please don't hesitate to contact us.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default CancellationRefundPolicy;
