import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const ShippingPolicy = () => {
    return (
        <div>
            <Navbar />

            <div className=" mx-[10px] md:mx-[20px] overflow-hidden px-4 py-8">
                <h2 className="pl-0 mb-10 text-2xl md:text-21xl leading-none text-center "><span className="bg-black text-white px-4 rounded-lg">Shipping Policy</span></h2>
                <p className="text-lg text-gray-900 mb-8">At cuet.testknock.com, we understand education is a journey, not a destination. That's why we offer lifetime access to our digital resources, empowering you to prepare for your CUET exam at your own pace. Since our products are entirely digital, there's no physical shipping involved. However, this page outlines how you'll receive access and confirmation of your purchase.</p>
                <h2 className="text-2xl font-bold mb-4">What to Expect After Purchase?</h2>
                <p className="text-lg text-gray-900 mb-4">Once you complete your purchase on cuet.testknock.com, you'll receive two immediate confirmations:</p>
                <ul className="list-disc md:ml-6 ml-2 mb-8">
                    <li className='py-4 text-lg'><strong>Email Confirmation:</strong> An email containing your purchase details and a link to access your personalized dashboard will be sent to the email address you provided during checkout.</li>
                    <li className='py-4 text-lg'><strong>SMS Confirmation (if applicable):</strong> Depending on your mobile carrier and country, you may receive an SMS notification confirming your purchase.</li>
                </ul>

                <h2 className="text-2xl font-bold mb-4">Accessing Your Digital Products</h2>
                <p className="text-lg text-gray-900 mb-4">Within the confirmation email, you'll find a link leading you to your personalized cuet.testknock.com dashboard. This dashboard serves as your central hub for accessing all your purchased resources, including:</p>
                <ul className="list-disc md:ml-6 ml-2 mb-8">
                    <li className='py-4 text-lg'><strong>Mock Tests:</strong> Simulate the real CUET exam experience with a variety of mock tests covering different subjects and difficulty levels.</li>
                    <li className='py-4 text-lg'><strong>Practice Tests:</strong> Hone your skills and identify areas for improvement with a vast library of focused practice tests.</li>
                    <li className='py-4 text-lg'><strong>Test Results: </strong>Analyze your performance on each test and track your progress over time through detailed reports.</li>
                    <li className='py-4 text-lg'><strong>Progress Reports:</strong> Gain valuable insights into your strengths and weaknesses with comprehensive progress reports generated from your test results.</li>
                </ul>

                <h2 className="text-2xl font-bold mb-4">Lifetime Access</h2>
                <p className="text-lg text-gray-900 mb-4">Unlike physical products, your access to the digital resources to cuet.testknock.com is permanent. This means you can revisit mock tests, retake practice tests, and analyze your progress reports throughout your CUET preparation journey – all within your personalized dashboard.</p>

                <h2 className="text-2xl font-bold mb-4">Still Have Questions?</h2>
                <p className="text-lg text-gray-900 mb-4">If you have any questions about accessing your digital products or encounter any issues with your confirmation emails or SMS notifications, please don't hesitate to contact us. We're here to ensure a smooth and successful learning experience on cuet.testknock.com.</p>
                <h2 className="text-2xl font-bold mb-4">Contact Us:</h2>
                <ul className="list-disc md:ml-6 ml-2 mb-8">
                    <li className="mb-2 ">Email:<a href="mailto:info@testknock.com"> info@testknock.com</a></li>
                    <li className="mb-2">Phone:<a href="tel:9560443520"> 9560443520</a></li>
                </ul>
            </div>
            <Footer />
        </div>
    );
};

export default ShippingPolicy;